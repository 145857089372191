import React, { useRef } from "react";
import { PageProps } from "gatsby";
import { Helmet } from "react-helmet";

import { Container } from "@util/standard";
import styled from "styled-components";

interface Props extends PageProps {}

const StyledIframe = styled.iframe<{ width?: string }>`
  // transition: all 0.3s ease 0s;
  // position: absolute;
  // width: 300px;
  // height: 1000px;
  // transform: translate(-50%, -50%);
  // top: 50%;
  // left: 50%;
  // display: block;
`;

export default function IndexPage() {
  const gridIframe = useRef(null);

  return <></>;
}
